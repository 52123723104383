<template>
  <div>
    <div v-if="isLoading">
      <b-spinner />
    </div>

    <div v-else>
      <h4 class="mb-2">
        Edit tournament {{ ` - ${formData?.name}` }}
      </h4>
      <p>
        <organizer-tournament-status-badge :status="formData?.status" />
      </p>
      <p>
        You can always edit all information and size of tournament later but try
        to enter accurate information when creating tournament
      </p>

      <validation-observer ref="validation">
        <b-form @submit.prevent>
          <b-row>
            <!-- Tournament data -->
            <b-col cols="9">
              <!-- LEFT form data -->
              <b-card>
                <b-row>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      label="Tournament name"
                      label-for="tournament-name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Tournament name"
                        rules="required"
                        vid="tournament-name"
                      >
                        <b-form-input
                          id="tournament-name"
                          v-model="formData.name"
                          placeholder="Tournament name"
                          maxlength="70"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- tournament type -->
                    <b-form-group
                      label="Tournament type"
                      label-for="tournament-type"
                    >
                      <b-form-radio-group
                        id="tournament-type"
                        v-model="formData.tournamentType"
                        :options="tournamentTypeOptions"
                        :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- discipline category -->
                    <b-form-group
                      label="Discipline category"
                      label-for="discipline-category"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Discipline category"
                        rules="required"
                      >
                        <v-select
                          id="discipline-category"
                          v-model="formData.disciplineCategory"
                          :options="fetchDisciplineCategories"
                          :reduce="category => category.code"
                          :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- discipline  -->
                    <b-form-group
                      label="Discipline"
                      label-for="discipline"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Discipline"
                        rules="required"
                      >
                        <v-select
                          id="discipline"
                          v-model="formData.discipline"
                          :options="fetchDisciplines"
                          :reduce="d => d.code"
                          :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- platforms -->
                    <b-form-group
                      label="Select platform (if cross-play is supported you can select more than one)"
                      label-for="platforms"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Platform"
                        rules="required"
                      >
                        <v-select
                          id="platforms"
                          v-model="formData.platforms"
                          :options="fetchPlatforms"
                          :reduce="platform => platform.code"
                          multiple
                          :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- participants number -->
                    <b-form-group
                      label="Participant format "
                      label-for="participant-type"
                    >
                      <b-form-radio-group
                        id="participant-type"
                        v-model="formData.participantsType"
                        :options="participantTypeOptions"
                        :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Max participants (size)"
                      label-for="participants-number"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Participants number"
                        rules="required"
                      >
                        <b-form-input
                          id="participants-number"
                          v-model="formData.participantsNumber"
                          placeholder="Enter number"
                          type="number"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-if="formData.participantsType === TournamentParticipantType.team"
                      label="Number of players per team (enter minimum players that must be in team so the team is able to join)"
                      label-for="players-per-team"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Players per team"
                        rules="integer"
                      >
                        <b-form-input
                          id="players-per-team"
                          v-model="formData.playersPerTeam"
                          placeholder="Enter number"
                          type="number"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Tournament start-->
                    <label>Tournament start</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Tournament start"
                      rules="required"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b-form-datepicker
                            v-model="formData.startDate"
                            :locale="getUserLocale()"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :start-weekday="1"
                            class="mb-1"
                            name="start-date"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                        <b-col cols="6">
                          <b-form-timepicker
                            v-model="formData.startTime"
                            :hour12="false"
                            locale="en"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                      </b-row>
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>

                    <!-- Timezone -->
                    <b-form-group
                      class="mt-1 mb-2"
                      label="Timezone"
                      label-for="timezone"
                    >
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="Timezone"
                        rules="required"
                      >
                        <v-select
                          id="timezone"
                          v-model="formData.timezone"
                          :class="{ 'is-invalid': !!componentErrors.length }"
                          :options="timezonesList"
                          :reduce="timezone => timezone.code"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    lg="6"
                    md="12"
                  >

                    <!-- Structure -->
                    <b-form-group>
                      <label for="reason">Structure of tournament</label>
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="type"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="formData.settings.tournamentFormat"
                          :options="tournamentFormatOptions"
                          :reduce="type => type.code"
                          name="type"
                          :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- General Format -->
                    <b-form-group
                      v-if="formData.settings.tournamentFormat !== TournamentFormat.RACE"
                      class="flex-fill"
                    >
                      <label for="reason">General format</label>
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="format"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="formData.settings.generalMatchFormat"
                          :options="matchTypeOptions"
                          :reduce="format => format.code"
                          name="format"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- 3rd/4th decider match?   -->
                    <b-form-group
                      v-if="formData.settings.tournamentFormat === TournamentFormat.SINGLE_ELIMINATION || formData.settings.tournamentFormat === TournamentFormat.DOUBLE_ELIMINATION"
                      label="3rd/4th decider match"
                      label-for="third-place-match"
                    >
                      <b-form-checkbox
                        v-model="formData.settings.thirdPlaceMatch"
                        checked="true"
                        class="custom-control-primary"
                        name="third-place-match"
                        switch
                        :disabled="disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- Finals have format -->
                    <b-form-group
                      v-if="formData.settings.tournamentFormat === 1 || formData.settings.tournamentFormat === 2"
                      label="Finals have other format?"
                      label-for="grand-final-match"
                    >
                      <b-form-checkbox
                        v-model="formData.settings.finalsHasOtherFormat"
                        checked="true"
                        class="custom-control-primary"
                        name="grand-final-match"
                        switch
                        :disabled="disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- Finals have other format -->
                    <b-form-group
                      v-if="formData.settings.finalsHasOtherFormat"
                      class="flex-fill"
                    >
                      <label for="reason">Finals format</label>
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="finalsMatchFormat"
                        rules="required"
                      >
                        <v-select
                          id="finalsMatchFormat"
                          v-model="formData.settings.finalsMatchFormat"
                          :options="matchFormatOptions"
                          :reduce="format => format.code"
                          name="finalsMatchFormat"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Lower bracket finals have format -->
                    <b-form-group
                      v-if="formData.settings.tournamentFormat === 2"
                      label="Lower bracket finals have other format?"
                      label-for="lower-bracket-finals-match"
                    >
                      <b-form-checkbox
                        v-model="formData.settings.lowerBracketFinalsHaveOtherFormat"
                        checked="true"
                        class="custom-control-primary"
                        name="lower-bracket-finals-match"
                        switch
                        :disabled=" disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- Lower bracket finals have other format -->
                    <b-form-group
                      v-if="formData.settings.lowerBracketFinalsHaveOtherFormat"
                      :disabled=" disabledWhenRunning || disabledWhenFinished"
                      class="flex-fill"
                    >
                      <label for="reason">Lower bracket finals format</label>
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="lowerBracketFinalsFormat"
                        rules="required"
                      >
                        <v-select
                          id="format"
                          v-model="formData.settings.lowerBracketFinalsFormat"
                          :options="matchFormatOptions"
                          :reduce="format => format.code"
                          name="lowerBracketFinalsFormat"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Semifinals have format -->
                    <b-form-group
                      v-if="formData.settings.tournamentFormat === 1 || formData.settings.tournamentFormat === 2"
                      label="Semi-Final have other format?"
                      label-for="semi-finals-match"
                    >
                      <b-form-checkbox
                        v-model="formData.settings.semiFinalsHaveOtherFormat"
                        checked="true"
                        class="custom-control-primary"
                        name="semi-finals-match"
                        switch
                        :disabled="disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- Semifinals have other format -->
                    <b-form-group
                      v-if="formData.settings.semiFinalsHaveOtherFormat"
                      class="flex-fill"
                    >
                      <label for="reason">Semi-Final format?</label>
                      <validation-provider
                        v-slot="{ errors: componentErrors }"
                        name="semiFinalsMatchFormat"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="formData.settings.semiFinalsMatchFormat"
                          :options="matchFormatOptions"
                          :reduce="format => format.code"
                          name="semiFinalsMatchFormat"
                          :disabled="disabledWhenRunning || disabledWhenFinished"
                        />
                        <small class="text-danger">
                          {{ componentErrors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <hr>

                    <!-- Enable registrations -->
                    <b-form-checkbox
                      v-model="formData.enableRegistration"
                      class="mt-1 mb-1"
                      :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                    >
                      Enable registrations
                    </b-form-checkbox>
                    <label>
                      If checked users have to register and join tournament on our
                      platform. If you are running offline event or you want to
                      enter players/teams and score manually then please uncheck
                      this option. When you start tournament empty bracket will
                      appear and you will have to enter teams/players.
                    </label>

                    <!-- Open registration-->
                    <div v-if="formData.enableRegistration">
                      <label>Registration open</label>
                      <b-row>
                        <b-col cols="6">
                          <b-form-datepicker
                            v-model="formData.registrationOpenDate"
                            :locale="getUserLocale()"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :start-weekday="1"
                            class="mb-1"
                            name="checkin-start-date"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                        <b-col cols="6">
                          <b-form-timepicker
                            v-model="formData.registrationOpenTime"
                            :hour12="false"
                            locale="en"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                      </b-row>

                      <!-- Close registration-->
                      <label>Registration close</label>
                      <b-row>
                        <b-col cols="6">
                          <b-form-datepicker
                            v-model="formData.registrationCloseDate"
                            :locale="getUserLocale()"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :start-weekday="1"
                            class="mb-1"
                            name="checkin-start-date"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                        <b-col cols="6">
                          <b-form-timepicker
                            v-model="formData.registrationCloseTime"
                            :hour12="false"
                            locale="en"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                      </b-row>
                    </div>

                    <!-- Enable Checkin -->
                    <b-form-checkbox
                      v-model="formData.enableCheckin"
                      class="mt-1 mb-1"
                      :disabled="disabledWhenRunning || disabledWhenFinished"
                      name="enable-checkin"
                    >
                      Enable Check-in
                    </b-form-checkbox>
                    <label class="mb-2">
                      If check-in is enabled teams/players need to check-in before
                      tournament starts and only checked-in teams/players can
                      participate. You can later setup when check-in starts and
                      ends. This option can be very useful for free tournaments to
                      avoid more than usual no-show matches in tournament. Keep in
                      mind that teams/players that don’t check-in in time and
                      tournament is started they can’t join and participate.
                    </label>

                    <!-- Checkin start-->
                    <div v-if="formData.enableCheckin">
                      <label>Checkin start</label>
                      <b-row>
                        <b-col cols="6">
                          <b-form-datepicker
                            v-model="formData.checkinStartDate"
                            :locale="getUserLocale()"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :start-weekday="1"
                            class="mb-1"
                            name="checkin-start-date"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                        <b-col cols="6">
                          <b-form-timepicker
                            v-model="formData.checkinStartTime"
                            :hour12="false"
                            locale="en"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                      </b-row>

                      <!-- Checking close-->
                      <label>Checkin close</label>
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            v-slot="{ errors: componentErrors }"
                            name="CheckinCloseDate"
                            :rules="`${formData.enableCheckin ? 'required' : ''}`"
                          >
                            <b-form-datepicker
                              v-model="formData.checkinCloseDate"
                              :locale="getUserLocale()"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              :start-weekday="1"
                              class="mb-1"
                              name="checkin-close-date"
                              placeholder=""
                              :disabled="disabledWhenRunning || disabledWhenFinished"
                            />
                            <small class="text-danger">
                              {{ componentErrors[0] }}
                            </small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="6">
                          <b-form-timepicker
                            v-model="formData.checkinCloseTime"
                            :hour12="false"
                            locale="en"
                            placeholder=""
                            :disabled="disabledWhenRunning || disabledWhenFinished"
                          />
                        </b-col>
                      </b-row>
                    </div>

                    <!-- Checkin end on start -->
                    <b-form-group>
                      <b-form-checkbox
                        v-model="formData.checkinEndOnStart"
                        class="mt-1"
                        :disabled="disabledWhenRunning || disabledWhenFinished"
                      >
                        End Checkin when tournament is started
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Participant & other info -->
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Below you can add all info for participants, mention sponsors etc."
                      label-for="participants-info"
                    >
                      <b-form-textarea
                        v-model="formData.info"
                        name="participants-info"
                        :disabled="disabledWhenFinished"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Tournament rules -->
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Here please add only tournament rules"
                      label-for="tournament-rules"
                    >
                      <b-form-textarea
                        v-model="formData.rules"
                        name="tournament-rules"
                        :disabled="disabledWhenFinished"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <!-- RIGHT tournament data -->
            <b-col cols="3">
              <!-- Prize data -->
              <b-card
                class=""
                title="Tournament type and prizes"
              >
                <b-row>
                  <b-col cols="12">
                    <!-- Entry Fee? -->
                    <b-form-group>
                      <b-form-checkbox
                        v-model="formData.hasEntryFee"
                        class="mt-1"
                        :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                      >
                        Enable participation fee
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- Fee per team/player -->
                    <b-form-group
                      v-if="formData.hasEntryFee"
                      label="Entry fee for player/team"
                      label-for="fee-amount"
                    >
                      <b-form-input
                        id="fee-amount"
                        v-model="formData.entryFee"
                        placeholder="Fee amount"
                        type="number"
                        :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>

                    <!-- Fee per team/player -->
                    <b-form-group
                      v-if="formData.hasEntryFee"
                      label="Your fee in % (earning)"
                      label-for="fee-amount-percent"
                    >
                      <b-form-input
                        id="fee-amount-percent"
                        v-model="formData.organizerFee"
                        placeholder="Fee amount"
                        type="number"
                        :disabled="disabledWhenPublished || disabledWhenRunning || disabledWhenFinished"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- prize distribution -->
                <b-row v-if="formData.entryFee" />

                <!-- additional prizes -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Add your prizes"
                      label-for="prizes"
                    >
                      <label class="mt-1 mb-1">
                        Add your prizes If you have prizes that will be delivered
                        by you please enter them below (each prize in new line).
                        Keep in mind that its crucial for you to deliver promised
                        prizes after event. If you would like to give money prize
                        to participants please get in touch with us.
                      </label>
                      <b-form-textarea
                        v-model="formData.prizes"
                        name="prizes"
                        :disabled="disabledWhenFinished"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

              <!-- Tournament staff -->
              <b-card
                class=""
                title="Tournament staff"
              >
                <validation-provider
                  v-slot="{ errors: componentErrors }"
                  name="Staff"
                  rules="required"
                >
                  <b-form-checkbox
                    v-for="member in organizer?.members"
                    :key="member.user?.id"
                    v-model="formData.staff"
                    :button-variant="
                      formData.staff && formData.staff.indexOf(member.user?.id) !== -1
                        ? 'primary'
                        : 'outline-primary'
                    "
                    :value="member.user?.id"
                    button
                    class="mr-1"
                    size="sm"
                    :disabled="disabledWhenFinished"
                  >
                    {{ member.user?.name }}
                  </b-form-checkbox>

                  <div class="mt-1">
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </div>
                </validation-provider>
              </b-card>

              <dismissible-message-box
                :auto-dismiss="5000"
                :show="showErrorNotifications"
                variant="danger"
                class="p-1"
              >
                <feather-icon
                  class="mr-50"
                  icon="InfoIcon"
                />
                {{ errors[0] }}
              </dismissible-message-box>

              <dismissible-message-box
                v-if="showSuccessNotifications"
                :auto-dismiss="5000"
                :show="showSuccessNotifications"
                variant="success"
                class="p-1"
              >
                {{ successMessage }}
              </dismissible-message-box>

              <div class="d-flex justify-content-between flex-wrap">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  :disabled="isSaving"
                  @click.prevent="saveTournament"
                >
                  <b-spinner
                    v-if="isSaving"
                    small
                  />
                  Save tournament
                </b-button>

                <b-button
                  v-if="tournament?.status_id === TournamentStatusType.DRAFT"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="info"
                  :disabled="isPublishing"
                  @click.prevent="publishTournament"
                >
                  <b-spinner
                    v-if="isPublishing"
                    small
                  />
                  Publish tournament
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="outline-primary"
                  @click.prevent="$router.go(-1)"
                >
                  Back
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Timezones } from '@/constants/Timezones'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BFormTimepicker,
  BRow, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { required } from '@validations'
import TournamentType from '@/constants/TournamentType'
import Platforms from '@/constants/Platforms'
import Disciplines from '@/constants/Disciplines'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import DisciplineCategory from '@/constants/DisciplineCategory'
import { parseISO, format } from 'date-fns'
import TournamentStatusType from '@/constants/TournamentStatusType'
import OrganizerTournamentStatusBadge from '@/views/dashboard/tournament/OrganizerTournamentStatusBadge.vue'
import { getUserLocale } from '@/views/common'
import TournamentFormat from '@/constants/TournamentFormat'

export default {
  components: {
    OrganizerTournamentStatusBadge,
    BSpinner,
    DismissibleMessageBox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSaving: false,
      isPublishing: false,
      finalsHasOtherFormat: false,
      formData: {
        id: null,
        name: null,
        status: null,
        disciplineCategory: null,
        discipline: null,
        participantsType: null,
        playersPerTeam: null,
        participantsNumber: null,
        tournamentType: null,
        platforms: null,
        startDate: null,
        startTime: null,
        enableRegistration: null,
        registrationOpenDate: null,
        registrationOpenTime: null,
        registrationCloseDate: null,
        registrationCloseTime: null,
        checkinStartDate: null,
        checkinStartTime: null,
        checkinCloseDate: null,
        checkinCloseTime: null,
        checkinEndOnStart: null,
        timezone: null,
        rules: null,
        info: null,
        staff: null,
        enableCheckin: false,
        settings: {
          finalsMatchFormat: null,
          finalsHasOtherFormat: false,
          generalMatchFormat: null,
          lowerBracketFinalsFormat: null,
          lowerBracketFinalsHaveOtherFormat: false,
          semiFinalsMatchFormat: null,
          semiFinalsHaveOtherFormat: false,
          thirdPlaceMatch: null,
          tournamentFormat: null,
        },
      },
      successMessage: '',
      tournament: null,
      TournamentType,
      TournamentParticipantType,
      required,
      isLoading: true,
      timezonesList: Timezones.map(timezone => ({
        label: timezone.value,
        code: timezone.id,
      })),
      showErrorNotifications: false,
      showSuccessNotifications: false,
      errors: [],
      matchFormatOptions: [
        {
          code: 1,
          label: 'Best of 1',
        },
        {
          code: 2,
          label: 'Best of 2',
        },
        {
          code: 3,
          label: 'Best of 3',
        },
        {
          code: 4,
          label: 'Best of 4',
        },
        {
          code: 5,
          label: 'Best of 5',
        },
      ],
    }
  },
  computed: {
    TournamentFormat() {
      return TournamentFormat
    },
    TournamentStatusType() {
      return TournamentStatusType
    },
    disabledWhenPublished() {
      return this.tournament?.status_id === TournamentStatusType.PUBLISHED
    },
    disabledWhenRunning() {
      return this.tournament?.status_id === TournamentStatusType.IN_PROGRESS
    },
    disabledWhenFinished() {
      return this.tournament?.status_id === TournamentStatusType.FINISHED || this.tournament?.status_id === TournamentStatusType.CLOSED
    },
    participantTypeOptions() {
      return this.$api.tournament.fetchParticipantTypeOptions()
    },
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
    tournamentTypeOptions() {
      return this.$api.tournament.fetchTournamentTypeOptions()
    },
    matchTypeOptions() {
      return this.$api.tournament.fetchMatchTypeOptions()
    },
    tournamentFormatOptions() {
      return this.$api.tournament.fetchTournamentFormatOptions()
    },
    fetchDisciplines() {
      if (!this.formData.disciplineCategory) {
        return []
      }

      return Disciplines.filter(discipline => discipline.category_id === this.formData.disciplineCategory)
        .map(discipline => ({
          label: discipline.title,
          code: discipline.id,
        }))
    },
    fetchDisciplineCategories() {
      return DisciplineCategory.map(
        item => ({
          label: item.title,
          code: item.id,
        }),
      )
    },
    fetchPlatforms() {
      return Platforms.map(item => ({
        label: item.title,
        code: Number(item.id),
      }))
    },
  },

  async created() {
    await this.fetchTournament()
  },

  methods: {
    getUserLocale,
    async fetchTournament() {
      const tournamentId = Number(this.$route.params.tournamentId)
      const organizerId = Number(this.$route.params.id)

      this.isLoading = true

      const { data } = await this.$api.tournament.fetchMyTournamentById(
        organizerId,
        tournamentId,
      )

      this.tournament = data

      this.isLoading = false

      this.formData.id = data.id
      this.formData.status = data.status_id
      this.formData.name = data.name
      this.formData.disciplineCategory = data.discipline.category.id
      this.formData.discipline = data.discipline.id
      this.formData.participantsType = data.participant_type_id
      this.formData.playersPerTeam = data.players_per_team
      this.formData.participantsNumber = data.participants_number
      this.formData.tournamentType = data.tournament_type_id
      this.formData.settings = {
        finalsMatchFormat: data.settings?.finals_match_format,
        finalsHasOtherFormat: !!data.settings?.finals_match_format,
        generalMatchFormat: data.settings?.general_match_format,
        lowerBracketFinalsFormat: data.settings?.lower_bracket_finals_format,
        lowerBracketFinalsHaveOtherFormat: !!data.settings?.lower_bracket_finals_format,
        semiFinalsMatchFormat: data.settings?.semi_finals_match_format,
        semiFinalsHaveOtherFormat: !!data.settings?.semi_finals_match_format,
        thirdPlaceMatch: data.settings?.third_place_match,
        tournamentFormat: data.settings?.tournament_format,
      }
      this.formData.platforms = data.platforms?.map(p => p.id)

      this.formData.startDate = data?.start_at ? format(parseISO(data.start_at), 'yyyy-MM-dd') : null
      this.formData.startTime = new Date(data.start_at).toLocaleTimeString()

      this.formData.enableRegistration = data.enable_registration

      this.formData.registrationOpenDate = data.enable_registration && data.registration_open_at
        ? format(parseISO(data.registration_open_at), 'yyyy-MM-dd')
        : null

      this.formData.registrationOpenTime = data.enable_registration
        ? new Date(data.registration_open_at).toLocaleTimeString()
        : null

      this.formData.registrationCloseDate = data.enable_registration && data.registration_close_at
        ? format(parseISO(data.registration_close_at), 'yyy-MM-dd')
        : null

      this.formData.registrationCloseTime = data.enable_registration
        ? new Date(data.registration_close_at).toLocaleTimeString()
        : null

      this.formData.enableCheckin = data.enable_checkin

      this.formData.checkinStartDate = data.enable_checkin
        ? format(parseISO(data.checkin_start_at), 'yyy-MM-dd')
        : null

      this.formData.checkinStartTime = data.enable_checkin
        ? new Date(data.checkin_start_at).toLocaleTimeString()
        : null

      this.formData.checkinCloseDate = data.enable_checkin
        ? format(parseISO(data.checkin_close_at), 'yyy-MM-dd')
        : null

      this.formData.checkinCloseTime = data.enable_checkin
        ? new Date(data.checkin_close_at).toLocaleTimeString()
        : null

      this.formData.checkinEndOnStart = data.checkin_end_on_tournament_start

      this.formData.timezone = data.timezone_id

      this.formData.rules = data.rules

      this.formData.info = data.info

      this.formData.prizes = data.prizes

      this.formData.staff = data.staff.map(member => member.user.id)
    },
    async publishTournament() {
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      this.isPublishing = true

      const { errors } = await this.$api.tournament.updateStatus(
        this.$route.params.tournamentId,
        TournamentStatusType.PUBLISHED,
      )

      this.isPublishing = false

      if (!errors || errors.length === 0) {
        this.successMessage = 'Tournament successfully published'
        this.showSuccessNotifications = true
        this.tournament.status_id = TournamentStatusType.PUBLISHED
      }

      await this.fetchTournament()
    },
    async saveTournament() {
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.errors = []

      const formValid = await this.$refs.validation.validate()

      if (!formValid) {
        return
      }

      const payload = {
        id: this.formData.id,
        organizer_id: Number(this.$route.params.id),
        name: this.formData.name,
        discipline_id: Number(this.formData.discipline),
        image: null,
        status_id: this.formData.status,
        participants_number: Number(this.formData.participantsNumber),
        participant_type_id: this.formData.participantsType,
        players_per_team: this.formData.playersPerTeam
          ? Number(this.formData.playersPerTeam)
          : null,
        timezone_id: this.formData.timezone,
        platforms: this.platforms
          ? this.platforms.map(platform => platform.code)
          : [],
        info: this.formData.info,
        rules: this.formData.rules,
        password: null,
        start_at: `${this.formData.startDate} ${this.formData.startTime}`,
        enable_registration: this.formData.enableRegistration,
        registration_open_at: this.formData.enableRegistration
          ? `${this.formData.registrationOpenDate} ${this.formData.registrationOpenTime}`
          : null,
        registration_close_at: this.formData.enableRegistration
          ? `${this.formData.registrationCloseDate} ${this.formData.registrationCloseTime}`
          : null,
        enable_checkin: this.formData.enableCheckin,
        checkin_start_at: this.formData.enableCheckin
          ? `${this.formData.checkinStartDate} ${this.formData.checkinStartTime}`
          : null,
        checkin_close_at: this.formData.enableCheckin
          ? `${this.formData.checkinCloseDate} ${this.formData.checkinCloseTime}`
          : null,
        checkin_end_on_tournament_start: this.formData.checkinEndOnStart,
        has_participation_fee: this.formData.hasEntryFee,
        entry_fee: this.formData.entryFee ? parseFloat(this.formData.entryFee) : null,
        organizer_fee: this.formData.organizerFee
          ? parseFloat(this.formData.organizerFee)
          : null,
        staff: this.formData.staff ? this.formData.staff : null,
        prizes: this.formData.prizes,
        tournament_type_id: this.formData.tournamentType,
        settings: {
          tournament_format: this.formData.settings.tournamentFormat,
          general_match_format: this.formData.settings.generalMatchFormat,
          third_place_match: this.formData.settings.thirdPlaceMatch,
          finals_match_format: this.formData.settings.finalsHasOtherFormat ? this.formData.settings.finalsMatchFormat : null,
          semi_finals_match_format: this.formData.settings.semiFinalsHaveOtherFormat ? this.formData.settings.semiFinalsMatchFormat : null,
          lower_bracket_finals_format: this.formData.settings.lowerBracketFinalsHaveOtherFormat ? this.formData.settings.lowerBracketFinalsFormat : null,
        },
      }

      this.isSaving = true

      const {
        data,
        errors,
      } = await this.$api.tournament.updateTournament(
        payload,
      )

      this.isSaving = false

      if (errors && errors.length) {
        this.errors.push(
          'There was an error updating tournament. Please check all mandatory fields are correct and try again.',
        )

        this.showErrorNotifications = true

        return
      }

      this.successMessage = 'Tournament successfully saved. You\'ll be redirected to tournament overview in 5 seconds.'
      this.showSuccessNotifications = true

      setTimeout(async () => {
        await this.$router.push({
          name: 'dashboard-tournament-overview',
          params: {
            id: Number(this.$route.params.id),
            tournamentId: data.id,
          },
        })
      }, 5000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
