var render = function () {
  var _vm$formData, _vm$formData2, _vm$tournament;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('div', [_c('b-spinner')], 1) : _c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" Edit tournament " + _vm._s(" - ".concat((_vm$formData = _vm.formData) === null || _vm$formData === void 0 ? void 0 : _vm$formData.name)) + " ")]), _c('p', [_c('organizer-tournament-status-badge', {
    attrs: {
      "status": (_vm$formData2 = _vm.formData) === null || _vm$formData2 === void 0 ? void 0 : _vm$formData2.status
    }
  })], 1), _c('p', [_vm._v(" You can always edit all information and size of tournament later but try to enter accurate information when creating tournament ")]), _c('validation-observer', {
    ref: "validation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', {
    class: _vm.errors.length > 0 ? 'is-invalid' : null,
    attrs: {
      "label": "Tournament name",
      "label-for": "tournament-name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tournament name",
      "rules": "required",
      "vid": "tournament-name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "tournament-name",
            "placeholder": "Tournament name",
            "maxlength": "70",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tournament type",
      "label-for": "tournament-type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "tournament-type",
      "options": _vm.tournamentTypeOptions,
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.tournamentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "tournamentType", $$v);
      },
      expression: "formData.tournamentType"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Discipline category",
      "label-for": "discipline-category"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline category",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline-category",
            "options": _vm.fetchDisciplineCategories,
            "reduce": function reduce(category) {
              return category.code;
            },
            "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.disciplineCategory,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "disciplineCategory", $$v);
            },
            expression: "formData.disciplineCategory"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Discipline",
      "label-for": "discipline"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline",
            "options": _vm.fetchDisciplines,
            "reduce": function reduce(d) {
              return d.code;
            },
            "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.discipline,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "discipline", $$v);
            },
            expression: "formData.discipline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Select platform (if cross-play is supported you can select more than one)",
      "label-for": "platforms"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Platform",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var componentErrors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "platforms",
            "options": _vm.fetchPlatforms,
            "reduce": function reduce(platform) {
              return platform.code;
            },
            "multiple": "",
            "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.platforms,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "platforms", $$v);
            },
            expression: "formData.platforms"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Participant format ",
      "label-for": "participant-type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "participant-type",
      "options": _vm.participantTypeOptions,
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.participantsType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "participantsType", $$v);
      },
      expression: "formData.participantsType"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Max participants (size)",
      "label-for": "participants-number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var componentErrors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "participants-number",
            "placeholder": "Enter number",
            "type": "number",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.participantsNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "participantsNumber", $$v);
            },
            expression: "formData.participantsNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.formData.participantsType === _vm.TournamentParticipantType.team ? _c('b-form-group', {
    attrs: {
      "label": "Number of players per team (enter minimum players that must be in team so the team is able to join)",
      "label-for": "players-per-team"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Players per team",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var componentErrors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "players-per-team",
            "placeholder": "Enter number",
            "type": "number",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.playersPerTeam,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "playersPerTeam", $$v);
            },
            expression: "formData.playersPerTeam"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3033758417)
  })], 1) : _vm._e(), _c('label', [_vm._v("Tournament start")]), _c('validation-provider', {
    attrs: {
      "name": "Tournament start",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var componentErrors = _ref7.errors;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-datepicker', {
          staticClass: "mb-1",
          attrs: {
            "locale": _vm.getUserLocale(),
            "date-format-options": {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },
            "start-weekday": 1,
            "name": "start-date",
            "placeholder": "",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "hour12": false,
            "locale": "en",
            "placeholder": "",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.startTime,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "startTime", $$v);
            },
            expression: "formData.startTime"
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  }), _c('b-form-group', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "label": "Timezone",
      "label-for": "timezone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Timezone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var componentErrors = _ref8.errors;
        return [_c('v-select', {
          class: {
            'is-invalid': !!componentErrors.length
          },
          attrs: {
            "id": "timezone",
            "options": _vm.timezonesList,
            "reduce": function reduce(timezone) {
              return timezone.code;
            },
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.timezone,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "timezone", $$v);
            },
            expression: "formData.timezone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Structure of tournament")]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var componentErrors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.tournamentFormatOptions,
            "reduce": function reduce(type) {
              return type.code;
            },
            "name": "type",
            "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.settings.tournamentFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.settings, "tournamentFormat", $$v);
            },
            expression: "formData.settings.tournamentFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.formData.settings.tournamentFormat !== _vm.TournamentFormat.RACE ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("General format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var componentErrors = _ref10.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchTypeOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.settings.generalMatchFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.settings, "generalMatchFormat", $$v);
            },
            expression: "formData.settings.generalMatchFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2486295711)
  })], 1) : _vm._e(), _vm.formData.settings.tournamentFormat === _vm.TournamentFormat.SINGLE_ELIMINATION || _vm.formData.settings.tournamentFormat === _vm.TournamentFormat.DOUBLE_ELIMINATION ? _c('b-form-group', {
    attrs: {
      "label": "3rd/4th decider match",
      "label-for": "third-place-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "third-place-match",
      "switch": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.settings.thirdPlaceMatch,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settings, "thirdPlaceMatch", $$v);
      },
      expression: "formData.settings.thirdPlaceMatch"
    }
  })], 1) : _vm._e(), _vm.formData.settings.tournamentFormat === 1 || _vm.formData.settings.tournamentFormat === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Finals have other format?",
      "label-for": "grand-final-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "grand-final-match",
      "switch": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.settings.finalsHasOtherFormat,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settings, "finalsHasOtherFormat", $$v);
      },
      expression: "formData.settings.finalsHasOtherFormat"
    }
  })], 1) : _vm._e(), _vm.formData.settings.finalsHasOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Finals format")]), _c('validation-provider', {
    attrs: {
      "name": "finalsMatchFormat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var componentErrors = _ref11.errors;
        return [_c('v-select', {
          attrs: {
            "id": "finalsMatchFormat",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "finalsMatchFormat",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.settings.finalsMatchFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.settings, "finalsMatchFormat", $$v);
            },
            expression: "formData.settings.finalsMatchFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1060512598)
  })], 1) : _vm._e(), _vm.formData.settings.tournamentFormat === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Lower bracket finals have other format?",
      "label-for": "lower-bracket-finals-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "lower-bracket-finals-match",
      "switch": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.settings.lowerBracketFinalsHaveOtherFormat,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settings, "lowerBracketFinalsHaveOtherFormat", $$v);
      },
      expression: "formData.settings.lowerBracketFinalsHaveOtherFormat"
    }
  })], 1) : _vm._e(), _vm.formData.settings.lowerBracketFinalsHaveOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill",
    attrs: {
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    }
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Lower bracket finals format")]), _c('validation-provider', {
    attrs: {
      "name": "lowerBracketFinalsFormat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var componentErrors = _ref12.errors;
        return [_c('v-select', {
          attrs: {
            "id": "format",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "lowerBracketFinalsFormat",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.settings.lowerBracketFinalsFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.settings, "lowerBracketFinalsFormat", $$v);
            },
            expression: "formData.settings.lowerBracketFinalsFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3046989850)
  })], 1) : _vm._e(), _vm.formData.settings.tournamentFormat === 1 || _vm.formData.settings.tournamentFormat === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Semi-Final have other format?",
      "label-for": "semi-finals-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "semi-finals-match",
      "switch": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.settings.semiFinalsHaveOtherFormat,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settings, "semiFinalsHaveOtherFormat", $$v);
      },
      expression: "formData.settings.semiFinalsHaveOtherFormat"
    }
  })], 1) : _vm._e(), _vm.formData.settings.semiFinalsHaveOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Semi-Final format?")]), _c('validation-provider', {
    attrs: {
      "name": "semiFinalsMatchFormat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var componentErrors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "semiFinalsMatchFormat",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.settings.semiFinalsMatchFormat,
            callback: function callback($$v) {
              _vm.$set(_vm.formData.settings, "semiFinalsMatchFormat", $$v);
            },
            expression: "formData.settings.semiFinalsMatchFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3598388257)
  })], 1) : _vm._e(), _c('hr'), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.enableRegistration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "enableRegistration", $$v);
      },
      expression: "formData.enableRegistration"
    }
  }, [_vm._v(" Enable registrations ")]), _c('label', [_vm._v(" If checked users have to register and join tournament on our platform. If you are running offline event or you want to enter players/teams and score manually then please uncheck this option. When you start tournament empty bracket will appear and you will have to enter teams/players. ")]), _vm.formData.enableRegistration ? _c('div', [_c('label', [_vm._v("Registration open")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.registrationOpenDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "registrationOpenDate", $$v);
      },
      expression: "formData.registrationOpenDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.registrationOpenTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "registrationOpenTime", $$v);
      },
      expression: "formData.registrationOpenTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Registration close")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.registrationCloseDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "registrationCloseDate", $$v);
      },
      expression: "formData.registrationCloseDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.registrationCloseTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "registrationCloseTime", $$v);
      },
      expression: "formData.registrationCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished,
      "name": "enable-checkin"
    },
    model: {
      value: _vm.formData.enableCheckin,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "enableCheckin", $$v);
      },
      expression: "formData.enableCheckin"
    }
  }, [_vm._v(" Enable Check-in ")]), _c('label', {
    staticClass: "mb-2"
  }, [_vm._v(" If check-in is enabled teams/players need to check-in before tournament starts and only checked-in teams/players can participate. You can later setup when check-in starts and ends. This option can be very useful for free tournaments to avoid more than usual no-show matches in tournament. Keep in mind that teams/players that don’t check-in in time and tournament is started they can’t join and participate. ")]), _vm.formData.enableCheckin ? _c('div', [_c('label', [_vm._v("Checkin start")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.checkinStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "checkinStartDate", $$v);
      },
      expression: "formData.checkinStartDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.checkinStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "checkinStartTime", $$v);
      },
      expression: "formData.checkinStartTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Checkin close")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "CheckinCloseDate",
      "rules": "".concat(_vm.formData.enableCheckin ? 'required' : '')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var componentErrors = _ref14.errors;
        return [_c('b-form-datepicker', {
          staticClass: "mb-1",
          attrs: {
            "locale": _vm.getUserLocale(),
            "date-format-options": {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },
            "start-weekday": 1,
            "name": "checkin-close-date",
            "placeholder": "",
            "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
          },
          model: {
            value: _vm.formData.checkinCloseDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "checkinCloseDate", $$v);
            },
            expression: "formData.checkinCloseDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2578272277)
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": "",
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.checkinCloseTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "checkinCloseTime", $$v);
      },
      expression: "formData.checkinCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-1",
    attrs: {
      "disabled": _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.checkinEndOnStart,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "checkinEndOnStart", $$v);
      },
      expression: "formData.checkinEndOnStart"
    }
  }, [_vm._v(" End Checkin when tournament is started ")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Below you can add all info for participants, mention sponsors etc.",
      "label-for": "participants-info"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "participants-info",
      "disabled": _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.info,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "info", $$v);
      },
      expression: "formData.info"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Here please add only tournament rules",
      "label-for": "tournament-rules"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "tournament-rules",
      "disabled": _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.rules,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "rules", $$v);
      },
      expression: "formData.rules"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Tournament type and prizes"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-1",
    attrs: {
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.hasEntryFee,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "hasEntryFee", $$v);
      },
      expression: "formData.hasEntryFee"
    }
  }, [_vm._v(" Enable participation fee ")])], 1), _vm.formData.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Entry fee for player/team",
      "label-for": "fee-amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount",
      "placeholder": "Fee amount",
      "type": "number",
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.entryFee,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "entryFee", $$v);
      },
      expression: "formData.entryFee"
    }
  })], 1) : _vm._e(), _vm.formData.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Your fee in % (earning)",
      "label-for": "fee-amount-percent"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount-percent",
      "placeholder": "Fee amount",
      "type": "number",
      "disabled": _vm.disabledWhenPublished || _vm.disabledWhenRunning || _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.organizerFee,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "organizerFee", $$v);
      },
      expression: "formData.organizerFee"
    }
  })], 1) : _vm._e()], 1)], 1), _vm.formData.entryFee ? _c('b-row') : _vm._e(), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Add your prizes",
      "label-for": "prizes"
    }
  }, [_c('label', {
    staticClass: "mt-1 mb-1"
  }, [_vm._v(" Add your prizes If you have prizes that will be delivered by you please enter them below (each prize in new line). Keep in mind that its crucial for you to deliver promised prizes after event. If you would like to give money prize to participants please get in touch with us. ")]), _c('b-form-textarea', {
    attrs: {
      "name": "prizes",
      "disabled": _vm.disabledWhenFinished
    },
    model: {
      value: _vm.formData.prizes,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "prizes", $$v);
      },
      expression: "formData.prizes"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "title": "Tournament staff"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Staff",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var _vm$organizer;
        var componentErrors = _ref15.errors;
        return [_vm._l((_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.members, function (member) {
          var _member$user, _member$user2, _member$user3, _member$user4;
          return _c('b-form-checkbox', {
            key: (_member$user = member.user) === null || _member$user === void 0 ? void 0 : _member$user.id,
            staticClass: "mr-1",
            attrs: {
              "button-variant": _vm.formData.staff && _vm.formData.staff.indexOf((_member$user2 = member.user) === null || _member$user2 === void 0 ? void 0 : _member$user2.id) !== -1 ? 'primary' : 'outline-primary',
              "value": (_member$user3 = member.user) === null || _member$user3 === void 0 ? void 0 : _member$user3.id,
              "button": "",
              "size": "sm",
              "disabled": _vm.disabledWhenFinished
            },
            model: {
              value: _vm.formData.staff,
              callback: function callback($$v) {
                _vm.$set(_vm.formData, "staff", $$v);
              },
              expression: "formData.staff"
            }
          }, [_vm._v(" " + _vm._s((_member$user4 = member.user) === null || _member$user4 === void 0 ? void 0 : _member$user4.name) + " ")]);
        }), _c('div', {
          staticClass: "mt-1"
        }, [_c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])])];
      }
    }])
  })], 1), _c('dismissible-message-box', {
    staticClass: "p-1",
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showErrorNotifications,
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _vm.showSuccessNotifications ? _c('dismissible-message-box', {
    staticClass: "p-1",
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showSuccessNotifications,
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between flex-wrap"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.isSaving
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveTournament($event);
      }
    }
  }, [_vm.isSaving ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Save tournament ")], 1), ((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) === _vm.TournamentStatusType.DRAFT ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "info",
      "disabled": _vm.isPublishing
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.publishTournament($event);
      }
    }
  }, [_vm.isPublishing ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Publish tournament ")], 1) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Back ")])], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }